import React, { ReactElement } from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

interface Props {
  children: ReactElement;
}

function AuthLayout({ children }: Props) {
  return (
    <Layout>
      <Content>
        <div>
          {children}
        </div>
      </Content>
    </Layout>

  );
}

export default AuthLayout;
