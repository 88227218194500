import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Card } from 'antd';
import React, { useEffect } from 'react';
import { Line, Radar } from 'react-chartjs-2';

am4core.useTheme(am4themes_animated);

const options = {
  lineTension: 0.3,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

interface props {
  data:Array<number>
  color:any
  color_alpha: any
  label: string
  labels:any
}

const ChartWindDirection: React.FC<props> = (props) => {
  const dataChart = {
    labels: props.labels,
    datasets: [
      {
        label: props.label,
        data: props.data,
        fill: true,
        backgroundColor: props.color_alpha,
        borderColor: props.color,
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    console.log('besttime');
  }, []);

  return (
    <Card bodyStyle={{ padding: '8px' }}>
      <Radar data={dataChart} />
    </Card>
  );
};

export default ChartWindDirection;
