import { Button, Col, Form, Grid, Input, notification, Row, Spin } from 'antd';
import * as Api from 'api';
import bgAuth from 'assets/image/bg-auth.jpg';
import logoPng from 'assets/image/hunt_control_logo.png';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from 'redux/auth/auth.hook';
import history from 'redux/history';
import { PATHS } from 'res/routes';
import storage from 'store';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const AuthEnterPassword = () => {
  const { md } = useBreakpoint();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {
    const email = storage.get('reset_email');
    const code = storage.get('reset_code');

    if (email == null || email.length < 2 || code == null || code.length < 4) {
      history.push(PATHS.AUTH_RESET_PASSWORD);
    }

    console.log('onFinish ====>', values);
    if (values.password != values.confirm) {
      notification.open({
        message: 'Please input same password.',
      });
      return;
    }

    if (values.password.length < 4) {
      notification.open({
        message: 'Please input at least 4 characters.',
      });
      return;
    }

    setLoading(true);
    Api.AUTH_PASSWORD_SET({ password: values.password, code, email }).then(((res: any) => {
      setLoading(false);
      const result = JSON.parse(res.text);
      if (result.data.status == 200) {
        notification.open({
          message: result.message,
        });
        history.push(PATHS.SIGNIN);
      } else {
        notification.open({
          message: result.message,
        });
      }
      // setForecastdata(data.data);
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (auth.profile) {
      history.push(PATHS.HOME);
    }
  }, [auth.profile]);

  return (
    <Row className={classes.authWrapper}>
      {md && (
        <Col md={8}>
          <div className={classes.leftSide}>
            <img className={classes.authBg} src={bgAuth} alt="" />
            <div className={classes.logoWrapper}>
              <img src={logoPng} alt="" />
              <h1>HuntControl</h1>
            </div>

          </div>
        </Col>
      )}
      <Col md={16} xs={24}>
        <div className={classes.rightpanel}>
          <div className={classes.signupFormHeader}>
            <span>Already have an account?</span>
            <a href="/signin">Sign in</a>
          </div>
          <div className={classes.siginFormWrapper}>
            <Spin spinning={loading} size="large">
              <Form initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <div className={classes.formWrapper} style={{ width: md ? 500 : 300 }}>
                  <h3>Password</h3>
                  <Form.Item name="password" rules={[{ required: true, message: 'Please input new password!' }]}>
                    <Input.Password size="large" />
                  </Form.Item>
                  <h3>Confirm</h3>
                  <Form.Item name="confirm">
                    <Input.Password size="large" />
                  </Form.Item>
                  <Form.Item>
                    <Button className={classes.btnSignin} htmlType="submit" size="large">
                      Continue
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Spin>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default AuthEnterPassword;
