import { Button, Checkbox, Col, DatePicker, Divider, Form, notification, Radio, Row, Select, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import * as Api from 'api';
import React, { useEffect, useState } from 'react';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const SubImageFilter = (props) => {
  const [form] = Form.useForm();
  const { auth: { profile } } = useAuth();
  const { filterOptions, setFilterOption } = useApi();
  const { md } = useBreakpoint();
  const [users, setUsers] = useState<any[]>([]);
  const [cameras, setCameras] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);

  useEffect(() => {
    if (profile) {
      http_load_tags();
    }
  }, [profile]);

  const onValuesChange = (values, allvalues) => {
    setFilterOption({ ...allvalues, user_id: profile.user_id });
  };

  const onChangeDate = (date: any, dateString: string[]) => {
    setFilterOption({ ...filterOptions, fromDate: dateString[0], toDate: dateString[1] });
  };

  const http_load_tags = () => {
    Api.SUB_USERS_CAMERAS_TAGS_ALL().then(((res: any) => {
      const jsonObject = JSON.parse(res.text);
      setTags(jsonObject.tags);
      setUsers(jsonObject.users);
      setCameras(jsonObject.cameras);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const changeDeerSubCategroy = (e) => {
    const val = e.target.value;
    setFilterOption({ ...filterOptions, antlered: val });
  };

  return (
    <div className={classes.wrapper}>
      <Form form={form} style={{ marginTop: md ? 20 : 0 }} onValuesChange={onValuesChange}>
        <Form.Item label="Sort" name="sort" style={{ marginBottom: 8 }} className={classes.formInputWrapper} initialValue="DESC">
          <Select size="large" dropdownStyle={{ position: 'fixed' }}>
            <Option key="DESC" value="DESC">Newest to Oldest</Option>
            <Option key="ASC" value="ASC">Oldest to Newest</Option>
          </Select>
        </Form.Item>
        <Divider style={{ marginBottom: 8 }}>Filter</Divider>
        {(props.imageFilter == 'deer' || props.imageFilter == 'Deer') && (
          <Radio.Group value={filterOptions.antlered} buttonStyle="solid" className={classes.categoryGroup} onChange={changeDeerSubCategroy}>
            <Row>
              <Col span={12}><Radio.Button className={classes.categoryBtn} value="0">All Deer</Radio.Button></Col>
              <Col span={12}><Radio.Button className={classes.categoryBtn} value="1">Bucks Only</Radio.Button></Col>
            </Row>
          </Radio.Group>
        )}
        <Form.Item name="sub_user_id" className={classes.formInputWrapper} initialValue="0">
          <Select size="large" dropdownStyle={{ position: 'fixed' }}>
            <Option key={0} value="0">All Sub Users</Option>
            <OptGroup label="users">
              {users.map((val: any) => (
                <Option key={val.id} value={val.id}>{val.name}</Option>
              ))}
            </OptGroup>
          </Select>
        </Form.Item>
        <Form.Item name="camera_id" className={classes.formInputWrapper} initialValue="0">
          <Select size="large" dropdownStyle={{ position: 'fixed' }}>
            <Option key={0} value="0">All Cameras</Option>
            <OptGroup label="Cameras">
              {cameras.map((val: any) => (
                <Option key={val.id} value={val.id}>{val.name}</Option>
              ))}
            </OptGroup>
          </Select>
        </Form.Item>
        <Form.Item name="tag" className={classes.formInputWrapper} initialValue="">
          <Select size="large" dropdownStyle={{ position: 'fixed' }}>
            <Option key={0} value="">All Tags</Option>
            <OptGroup label="tag">
              {tags.map((val: any) => (
                val.tag != '' && (<Option key={val.tag} value={val.tag}>{val.tag}</Option>)
              ))}
            </OptGroup>
          </Select>
        </Form.Item>
        <Form.Item className={classes.formInputWrapper}>
          <div>
            <RangePicker size="large" style={{ width: '100%' }} onChange={onChangeDate} />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SubImageFilter;
