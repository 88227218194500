import { ProfileType } from 'helpers/types';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as authAction from './auth.action';

const useAuth = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state: any) => state.authReducer);

  const signRequestSaga = useCallback(
    (payload) => {
      dispatch(authAction.auth(payload));
    },
    [dispatch],
  );

  const setProfile = useCallback(
    (payload:ProfileType) => {
      dispatch(authAction.setProfile(payload));
    },
    [dispatch],
  );

  return { auth, signRequestSaga, setProfile };
};

export default useAuth;
