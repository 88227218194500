import React from 'react';
import styles from './style.module.scss';

const ProgressBar = (props) => {
  const {
    isLoading = props.percent === 'loading',
    percent,
    info,
    size = 'small',
    showInfo = false,
  } = props;

  return (
    <div className={styles.progress_outer}>
      <div
        className={`${styles.progress} ${styles[size ? 'progress--'.concat(size) : '']} ${styles[isLoading ? 'progress--'.concat('loading') : '']}`}
      >
        <div className={styles.progress_bar} style={{ width: percent.toFixed(0).concat('%') }} />
      </div>

      {isLoading == false && showInfo ? (
        <span className={styles.progress_info}>{info}</span>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProgressBar;
