import { Button, Checkbox, Col, DatePicker, Form, Radio, Row, Select, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useEffect, useState } from 'react';
import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import moment from 'moment';
import { QuestionCircleFilled, StarOutlined } from '@ant-design/icons';
import classes from './style.module.scss';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';

const optionParams = [
  { value: 'windSpeed', label: 'Wind Speed' },
  { value: 'windDirection', label: 'Wind Direction' },
  { value: 'temp', label: 'Temperature' },
  { value: 'rh', label: 'Humidity' },
  { value: 'pressure', label: 'Pressure' },
];

const PredictionFilter = (props) => {
  const [form] = Form.useForm();
  const { md } = useBreakpoint();
  const { auth: { profile } } = useAuth();
  const [filterOptions, setFilterOption] = useState({
    category: 'Deer',
    camera_id: 0,
    startDate: '',
    endDate: '',
    tag: '',
    dateKind: '2',
    params: ['windSpeed', 'windDirection', 'temp'],
    params_for_api: {
      temp: 1,
      windSpeed: 1,
      windDirection: 1,
      rh: 0,
      pressure: 0,
    },
  });

  const [cameraArr, setCameraArr] = useState<any[]>([]);
  const [cameraGroups, setCameraGroups] = useState<any[]>([]);
  const [tagArr, setTagArr] = useState<any[]>([]);

  useEffect(() => {
    if (profile) {
      console.log('FilterForecast-->loading');
      Api.CAMERAS_GROUPS_TAGS().then(((res: any) => {
        const data = JSON.parse(res.text);
        setCameraArr(data.cameras);
        setCameraGroups(data.cameraGroups);
        setTagArr(data.tags);
        const newFilter = { ...filterOptions, camera_id: data.cameras[0] ? data.cameras[0].id : 0 };
        setFilterOption(newFilter);
        form.setFieldsValue(newFilter);
        props.onPredit(newFilter);
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  }, [profile]);

  const categoryAnimals = ['Deer', 'Hog', 'Turkey'];

  const onValuesChange = (values, allvalues) => {
    const newFilter = { ...filterOptions, ...allvalues };
    console.log(newFilter);
    setFilterOption(newFilter);
  };

  const onChangeDate = (date: any, dateString: string[]) => {
    const newFilter = { ...filterOptions, startDate: dateString[0], endDate: dateString[1], dateKind: '-1' };
    console.log(newFilter);
    setFilterOption(newFilter);
  };

  const onChangeDateKind = (e) => {
    const val = e.target.value;
    const newFilter = { ...filterOptions, dateKind: val, startDate: '', endDate: '' };
    setFilterOption(newFilter);
  };

  const onChangeParams = (checkedValues) => {
    const params = {
      temp: (checkedValues.indexOf('temp') > -1) ? 1 : 0,
      windSpeed: (checkedValues.indexOf('windSpeed') > -1) ? 1 : 0,
      windDirection: (checkedValues.indexOf('windDirection') > -1) ? 1 : 0,
      rh: (checkedValues.indexOf('rh') > -1) ? 1 : 0,
      pressure: (checkedValues.indexOf('pressure') > -1) ? 1 : 0,
    };

    const newFilter = { ...filterOptions, params: checkedValues, params_for_api: params };
    setFilterOption(newFilter);
  };
  return (
    <div className={classes.wrapper}>
      <Form form={form} style={{ marginTop: 20 }} onValuesChange={onValuesChange}>
        <Row>
          <Col xs={24}>
            <div className={classes.formInputLabel}>Select a Camera or Group</div>
            <Form.Item name="camera_id" className={classes.formInputWrapper}>
              <Select size="large">
                <OptGroup label="Cameras">
                  {cameraArr.map((val: any) => (
                    <Option key={val.id} value={val.id}>{val.name}</Option>
                  ))}
                </OptGroup>
                <OptGroup label="Camera Groups">
                  {cameraGroups.map((val: any) => (
                    <Option key={val.id} value={`group${val.id}`}>{val.name}</Option>
                  ))}
                </OptGroup>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <div className={classes.formInputLabel}>Select a Target</div>
            <Form.Item name="category" className={classes.formInputWrapper}>
              <Select size="large">
                {categoryAnimals.map((e, i) => (
                  <Option key={i} value={e}>{e}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <div className={classes.formInputLabel}>Select a Tag:</div>
            <Form.Item name="tag" className={classes.formInputWrapper}>
              <Select size="large">
                <Option key={0} value="">All</Option> :
                {tagArr.map((val: any) => (
                  val.tag != '' && (<Option key={val.tag} value={val.tag}>{val.tag}</Option>)
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <div className={classes.formInputLabel}>Choose Your Dates</div>
            <Form.Item className={classes.formInputWrapper}>
              <div>
                <RangePicker
                  size="large"
                  style={{ width: '100%' }}
                  onChange={onChangeDate}
                  format={dateFormat}
                  value={[filterOptions.startDate !== '' ? moment(filterOptions.startDate, dateFormat) : null, filterOptions.endDate !== '' ? moment(filterOptions.endDate, dateFormat) : null]}
                />
              </div>
            </Form.Item>
            <Radio.Group buttonStyle="solid" className={classes.categoryGroup} value={filterOptions.dateKind} onChange={onChangeDateKind}>
              <Row>
                <Col span={8}><Radio.Button className={classes.categoryBtn} value="0">2 Weeks</Radio.Button></Col>
                <Col span={8}><Radio.Button className={classes.categoryBtn} value="1">1 Month</Radio.Button></Col>
                <Col span={8}><Radio.Button className={classes.categoryBtn} value="2">All</Radio.Button></Col>
              </Row>
            </Radio.Group>
          </Col>
          <Col xs={24}>
            <div className={classes.formInputLabel}>Choose Your Parameters</div>
            <Checkbox.Group
              value={filterOptions.params}
              onChange={onChangeParams}
              options={optionParams}
              style={{ display: 'inline-block' }}
            />
          </Col>
          <Col xs={24}>
            <br />
            <Button type="primary" onClick={() => props.onPredit(filterOptions)} className={classes.categoryBtn}>
              Predict
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PredictionFilter;
