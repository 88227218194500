// function* getAllCameras(data: any) {
//   const { filter } = data.payload;
//   console.log('getAllCameras =====>', filter);
//   try {
//     const cameraRes = yield call(API.getCameraImages, filter);
//     console.log('cameraRes =====>', cameraRes);
//   } catch (e) {
//     console.log(e);
//   }
// //   yield put(actions.setCollections(colecRes.body.collections));
// }

export default function* watchAuthSaga() {
  // yield takeEvery(actions.getAllCameras, getAllCameras);
}
