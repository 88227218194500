import storage from 'store';

const localStore = Object.freeze({
  set: (type, value) => storage.set(type, value),
  get: (type) => storage.get(type),
  remove: (type) => storage.remove(type),
  clear: () => {
    storage.clearAll();
  },
  setToken: (token) => storage.set('auth_token', token),
  getToken: () => {
    let last_time = storage.get('auth_token_time');
    last_time = new Date(last_time).getTime() / 1000;
    const current_time = new Date().getTime() / 1000;
    const diff = (current_time - last_time);

    if (diff > 30 * 60) {
      storage.clearAll();
      window.location.href = '/';
      return null;
    }
    storage.set('auth_token_time', new Date().toLocaleString());
    return storage.get('auth_token');
  },
  removeToken: () => storage.remove('auth_token'),
});

export default localStore;
