import { Button, Col, Form, Grid, Row, Spin, notification } from 'antd';
import bgAuth from 'assets/image/bg-auth.jpg';
import logoPng from 'assets/image/hunt_control_logo.png';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import history from 'redux/history';
import { PATHS } from 'res/routes';
import ReactCodeInput from 'react-code-input';
import * as Api from 'api';
import storage from 'store';

import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const AuthRestEmailSent = () => {
  const { md } = useBreakpoint();
  const { signRequestSaga, auth } = useAuth();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  console.log('auth ==>', auth);

  const onFinish = (values: any) => {
    if (code.length < 4) {
      notification.open({
        message: 'Please input valid code',
      });
      return;
    }
    // signRequestSaga(values);
    const email = storage.get('reset_email');
    console.log('email', email);
    if (email == null || email.length < 2) {
      history.push(PATHS.AUTH_RESET_PASSWORD);
    }

    setLoading(true);
    Api.AUTH_PASSWORD_CODE({ email, code }).then(((res: any) => {
      setLoading(false);
      const result = JSON.parse(res.text);
      // console.log(result.data.status);
      if (result.data.status == 200) {
        storage.set('reset_code', code);
        history.push(PATHS.AUTH_ENTER_PASSWORD);
      } else {
        notification.open({
          message: result.message,
        });
      }
      // setForecastdata(data.data);
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (auth.profile) {
      history.push(PATHS.HOME);
    }
  }, [auth.profile]);

  return (
    <Row className={classes.authWrapper}>
      {md && (
        <Col md={8}>
          <div className={classes.leftSide}>
            <img className={classes.authBg} src={bgAuth} alt="" />
            <div className={classes.logoWrapper}>
              <img src={logoPng} alt="" />
              <h1>HuntControl</h1>
            </div>

          </div>
        </Col>
      )}
      <Col md={16} xs={24}>
        <div className={classes.rightpanel}>
          <div className={classes.signupFormHeader}>
            <span>Already have an account?</span>
            <a href="/signin">Sign in</a>
          </div>
          <div className={classes.siginFormWrapper}>
            <Spin spinning={loading} size="large">
              <Form initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <div className={classes.formWrapper} style={{ width: md ? 500 : 300 }}>
                  <h4>Verify code</h4>
                  <span>Didn&apos;t get the emsil? Check your spam folder or <a href="/authResetPassword">resend</a></span>
                  <div className={classes.divPin}>
                    <ReactCodeInput type="number" fields={4} name="Code" inputMode="numeric" onChange={(value) => setCode(value)} />
                  </div>
                  <Form.Item>
                    <Button className={classes.btnSignin} htmlType="submit" size="large">
                      Continue
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Spin>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default AuthRestEmailSent;
