import { ImageType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Row, Col, Grid, Spin } from 'antd';

import * as Api from 'api';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

interface MediaBoxProps {
  images: ImageType[]
  activeIndex: number
  onIndexChange: (index: number) => void
}

const moonPhaseLabels = ['New Moon', 'Wax. Crescent', '1st Quarter', 'Wax. Gibbous', 'Full Moon', 'Wan. Gibbous', '2nd Quarter', 'Wan. Crescent'];

const MediaBox: React.FC<MediaBoxProps> = ({ images, activeIndex, onIndexChange }) => {
  const [currentIndex, setCurrentIndex] = useState(activeIndex);
  const [loading, setLoading] = useState(false);
  const [imageDetail, setImageDetail] = useState<ImageType>();
  const { md } = useBreakpoint();
  useEffect(() => {
    setCurrentIndex(activeIndex);
  }, [activeIndex]);
  const detail = images[currentIndex];
  useEffect(() => {
    http_image_detail();
  }, [detail]);
  const mediaType = (detail.image_name.search('.MOV') > -1) ? 'video' : 'image';
  const onclickLeftArrow = () => {
    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = images.length - 1;
    }
    onIndexChange(newIndex);
  };

  const onclickRightArrow = () => {
    let newIndex = currentIndex + 1;
    if (newIndex > images.length - 1) {
      newIndex = 0;
    }
    onIndexChange(newIndex);
  };

  function moonPhaseIndex(moon) {
    let temp = 0;
    if (moon < 0.02) { temp = 0; } else if (moon >= 0.02 && moon <= 0.23) { temp = 1; } else if (moon > 0.23 && moon < 0.27) { temp = 2; } else if (moon >= 0.27 && moon <= 0.48) { temp = 3; } else if (moon > 0.48 && moon < 0.52) { temp = 4; } else if (moon >= 0.52 && moon <= 0.73) { temp = 5; } else if (moon > 0.73 && moon < 0.77) { temp = 6; } else if (moon >= 0.77 && moon <= 0.98) { temp = 7; }
    return temp;
  }

  const http_image_detail = () => {
    setLoading(true);
    Api.IMAGE_DETAIL(detail.id).then(((res: any) => {
      setLoading(false);
      const result = JSON.parse(res.text);
      setImageDetail(result);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };
  return (
    <Spin spinning={loading} size="large">
      <div className={classes.mediaboxWrapper}>
        {mediaType == 'video' && <ReactPlayer url={`https://d7s85wyrr26qk.cloudfront.net/${detail.image_name}`} controls width={1000} height="auto" />}
        {mediaType !== 'video' && <img className={classes.image} src={`https://d7s85wyrr26qk.cloudfront.net/${detail.image_name}`} alt="" />}
        <div className={classes.actionBar}>
          <span className={classes.leftArrow} onClick={onclickLeftArrow}>❮</span>
          <span className={classes.rightArrow} onClick={onclickRightArrow}>❯</span>
        </div>
        {imageDetail && (
        <div className={classes.cameraInfoText} style={{ height: md ? 60 : 120, bottom: md ? -60 : -120 }}>
          <Row style={{ height: '100%' }}>
            <Col md={8} xs={24}>
              <div className={classes.infoCol}>
                <span>
                  Weather
                </span>
                <span>
                  <img src={`/icons_weather/${imageDetail.icon}.png`} alt="" />
                </span>
                <span>{Math.round(imageDetail.temp)}F {imageDetail.rh}%RH {imageDetail.wind_direction} {imageDetail.wind_speed}MPH</span>
              </div>
            </Col>
            <Col md={8} xs={12}>
              <div className={classes.infoCol}>
                <span>Sun Rise: {imageDetail.sunrise} Set: {imageDetail.sunset}</span>
              </div>
            </Col>
            <Col md={8} xs={12}>
              <div className={classes.infoCol}>
                <span>
                  <img src={`/icons_moon/moon${moonPhaseIndex(imageDetail.moon)}.png`} alt="" />&nbsp;
                  {moonPhaseLabels[moonPhaseIndex(imageDetail.moon)]}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        )}
      </div>
    </Spin>
  );
};

export default MediaBox;
