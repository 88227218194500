import { PATHS } from 'res/routes';
import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from 'redux/auth/auth.hook';
import { getProfile } from 'api';

const PrivateRoute = (rest) => {
  const { auth: { profile }, setProfile } = useAuth();
  const isAuthenticated:any = localStorage.getItem('auth_token') || false;
  const [goToSign, setGotoSign] = useState((!isAuthenticated));
  useEffect(() => {
    if (!profile) {
      if (isAuthenticated) {
        getProfile().then((res:any) => {
          setProfile({ ...JSON.parse(res.text), user_id: isAuthenticated });
        }).catch(() => {
          setGotoSign(true);
        });
      }
    }
  }, []);

  if (profile) {
    return (<Route {...rest} />);
  }

  if (goToSign) {
    return (
      <Redirect
        push
        to={{
          pathname: PATHS.SIGNIN,
          state: { from: rest.location },
        }}
      />
    );
  }
  return null;
};

export default PrivateRoute;
