/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Form, Select } from 'antd';
import * as Api from 'api';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

// interface props {
//   detail: ImageType
//   onClick:()=>void;
// }

// const MyVideo:React.FC<MyVideoProps> = ({ children, ...props }) => <video {...props}>{children}</video>;

const { Option } = Select;
const ImageUploadCamera = (props) => {
  const [cameraArr, setCameraArr] = useState<any[]>([]);
  const { auth: { profile } } = useAuth();
  const [form] = Form.useForm();

  const loadData = () => {
    Api.CAMERA_GET_ALL().then(((res: any) => {
      const result = JSON.parse(res.text);
      console.log(result);
      setCameraArr(result.cameras);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  useEffect(() => {
    loadData();
    console.log('mount camera');
  }, [profile]);

  const onValuesChange = (values) => {
    // const newFilter = { ...filterOptions, ...allvalues };
    // setFilterOption(newFilter);
    // props.handleFilters(newFilter);
    props.selectCamera(values.camera_id);
  };
  return (
    <div>
      <Form form={form} style={{ marginTop: 20 }} onValuesChange={onValuesChange}>
        <div className={classes.formInputLabel}>Choose Camera:</div>
        <Form.Item name="camera_id" className={classes.formInputWrapper}>
          <Select size="large">
            {cameraArr.map((val: any) => (
              <Option key={val.id} value={val.id}>{val.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ImageUploadCamera;
