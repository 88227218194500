import { createTheme } from '@mui/material/styles';

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#dc6300',
    },
    warning: {
      main: '#aaa',
    },
  },
});

export default outerTheme;
