import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ConfigProvider } from 'antd';
import en_US from 'antd/lib/locale/en_US';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <ConfigProvider locale={en_US}>
    <App />
  </ConfigProvider>, document.getElementById('root'));

reportWebVitals();
