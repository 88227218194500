import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Card } from 'antd';
import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Label } from 'recharts';

am4core.useTheme(am4themes_animated);

const options = {
  lineTension: 0.3,
  scales: {
    y:
    {
      min: 0,
      stepSize: 1,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

interface props {
  data: Array<number>
  color: any
  color_alpha: any
  label: string
  labels: any
}

const ChartBesttimeLine: React.FC<props> = (props) => {
  const dataChart = {
    labels: props.labels,
    datasets: [
      {
        label: props.label,
        data: props.data,
        fill: true,
        backgroundColor: props.color_alpha,
        borderColor: props.color,
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    console.log('besttime');
  }, []);

  return (
    <Card bodyStyle={{ padding: '8px', textAlign: 'center' }}>
      <h3>{ props.label }</h3>
      <Line data={dataChart} options={options} />
    </Card>
  );
};

export default ChartBesttimeLine;
