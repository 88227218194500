import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface props {
  data: any,
  cameras: any,
  color:any
  color_alpha: any
}

const ChartSummaryBar: React.FC<props> = (props) => {
  const [chartHeight, setChartHeight] = useState(150);

  useEffect(() => {
    console.log('camera chart data', props.data);
    setChartHeight(props.data.length * 40 + 100);
  }, [props.data]);

  return (
    <Card bodyStyle={{ padding: '8px', height: chartHeight }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          width={500}
          height={400}
          data={props.data}
          margin={{
            top: 0,
            right: 40,
            bottom: 0,
            left: 0,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="count" type="number" />
          <Tooltip />
          <YAxis type="category" width={150} dataKey="name" />
          <Bar dataKey="count" barSize={20} fill={props.color_alpha} stroke={props.color} label={{ position: 'right' }} />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default ChartSummaryBar;
