/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { CameraOutlined, ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import { Card, Checkbox, Col, Row, Tag } from 'antd';
import { SubImageType } from 'helpers/types';
import React from 'react';
import * as Res from 'res';
import classes from './style.module.scss';

interface props {
  detail: SubImageType
}

const categoryColors = {
  Deer: Res.colorDeer_alpha2,
  Hog: Res.colorHog_alpha2,
  Turkey: Res.colorTurkey_alpha2,
  People: Res.colorPeople_alpha2,
  Vehicle: Res.colorVehicle_alpha2,
  Predator: Res.colorPredator_alpha2,
  Other: Res.colorOther_alpha2,
};

const SubImageCard: React.FC<props> = ({ detail }) => {
  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  detail.category = capitalizeFirstLetter(detail.category);
  const mediaType = (detail.image_name.search('.MOV') > -1) ? 'video' : 'image';

  return (
    <div style={{ padding: 8 }}>
      <Card
        hoverable
        //   style={{ width: 240 }}
        bodyStyle={{ padding: 0 }}
      >
        <div className={classes.cardCover}>
          {detail.category == 'Deer' && detail.category_sub == 'Antlered' && <img className={classes.img_antler} src="/icons/antler_icon.png" alt="" />}
          {mediaType == 'video' ?
            <img className={classes.image} src={`https://d7s85wyrr26qk.cloudfront.net/${detail.thumbnail}`} alt="" />
            : (
              <img className={classes.image} src={`https://d7s85wyrr26qk.cloudfront.net/${detail.image_name}`} alt="" />
            )}
          {detail.tag && <Tag className={classes.tag} color="orange">{detail.tag.split('$', 2).length > 1 ? detail.tag.split('$', 2)[1] : detail.tag}</Tag>}
        </div>
      </Card>
      <Row>
        <Col flex="auto">
          <div className={classes.labelCamera}><UserOutlined />&nbsp;{detail.user_name}</div>
          <div className={classes.labelCamera}><CameraOutlined />&nbsp;{detail.camera_name}</div>
          <div className={classes.labelTime}><ClockCircleOutlined />&nbsp;{detail.time}</div>
        </Col>
        <Col>
          <div className={classes.imageCategory} style={{ borderColor: categoryColors[detail.category], color: categoryColors[detail.category] }}>
            <div style={{ backgroundColor: categoryColors[detail.category] }}>&nbsp;</div>
            {detail.category}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SubImageCard;
