import React, { useEffect, useState } from 'react';
import { SignalCellular0Bar, SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar } from '@mui/icons-material';

function SignalComponent(props) {
  const { detail } = props;
  console.log(detail.version);
  console.log(detail.csq);
  if (detail.csq_percent > 0) {
    if (detail.csq_percent >= 90) {
      return <SignalCellular4Bar fontSize={props.fontSize} />;
    } if (detail.csq_percent >= 70) {
      return <SignalCellular3Bar fontSize={props.fontSize} />;
    } if (detail.csq_percent >= 40) {
      return <SignalCellular2Bar fontSize={props.fontSize} />;
    } if (detail.csq_percent >= 5) {
      return <SignalCellular1Bar fontSize={props.fontSize} />;
    }
    return <SignalCellular0Bar fontSize={props.fontSize} />;
  }
  if (detail.version == 'A' && detail.csq > 90) {
    return <SignalCellular4Bar fontSize={props.fontSize} />;
  } if (detail.version == 'A' && detail.csq > 70) {
    return <SignalCellular3Bar fontSize={props.fontSize} />;
  } if (detail.version == 'A' && detail.csq > 40) {
    return <SignalCellular2Bar fontSize={props.fontSize} />;
  } if (detail.version == 'A' && detail.csq > 5) {
    return <SignalCellular1Bar fontSize={props.fontSize} />;
  } if (detail.version == 'B' && detail.csq >= 4) {
    return <SignalCellular4Bar fontSize={props.fontSize} />;
  } if (detail.version == 'B' && detail.csq >= 3) {
    return <SignalCellular3Bar fontSize={props.fontSize} />;
  } if (detail.version == 'B' && detail.csq >= 2) {
    return <SignalCellular2Bar fontSize={props.fontSize} />;
  } if (detail.version == 'B' && detail.csq >= 1) {
    return <SignalCellular1Bar fontSize={props.fontSize} />;
  }
  return <SignalCellular0Bar fontSize={props.fontSize} />;
}
export default SignalComponent;
