import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import authReducer from './auth/auth.reducer';
import apiReducer from './api/api.reducer';
import watchAuthSaga from './auth/auth.saga';
import watchApiSaga from './api/api.saga';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  authReducer,
  apiReducer,
});

const store = createStore(
  rootReducer,
  undefined,
  compose(
    applyMiddleware(sagaMiddleware),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f,
  ),
);

sagaMiddleware.run(watchAuthSaga);
sagaMiddleware.run(watchApiSaga);

export default store;
