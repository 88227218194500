/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Card } from 'antd';
import classes from './style.module.scss';

interface props {
  detail: any
}

const DashboardImageCard: React.FC<props> = ({ detail }) => (
  <div style={{ padding: 8 }}>
    <Card
      hoverable
      //   style={{ width: 240 }}
      bodyStyle={{ padding: 0 }}
      cover={(
        <div className={classes.cardCover}>
          {detail.thumbnail == '' ?
            <img src={`https://d7s85wyrr26qk.cloudfront.net/${detail.image_name}`} alt="" />
            : (
              <img src={`https://d7s85wyrr26qk.cloudfront.net/${detail.thumbnail}`} alt="" />
            )}
        </div>
      )}
    />
  </div>
);
export default DashboardImageCard;
