/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import localStore from 'helpers/localstore';
import * as Api from 'api';
import Resizer from 'react-image-file-resizer';
import React from 'react';
import classes from './style.module.scss';

const ImageUploadBox = (props) => {
  const { Dragger } = Upload;
  console.log('box', props.camera_id);
  const propsDragger = {
    name: 'file',
    multiple: true,
    action: Api.IMAGE_UPLOAD_URL,
    beforeUpload: (file) => {
      console.log(file.type);
      const isPNG = file.type.includes('image') || file.type.includes('video');

      if (!isPNG) {
        message.error(`${file.name} is not a image/video file`);
      }

      return isPNG || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    data: {
      camera_id: props.camera_id,
    },
    headers: {
      authorization: localStore.getToken(),
    },
  };

  return (
    <div className={classes.wrapper}>
      {props.camera_id != 0 && (
        <Dragger
          {...propsDragger}
        // beforeUpload={(file) => new Promise((resolve: any) => {
        //   Resizer.imageFileResizer(
        //     file,
        //     1028,
        //     1028,
        //     'JPEG',
        //     80,
        //     0,
        //     (uri) => {
        //       resolve(uri);
        //     },
        //     'file',
        //   );
        // })}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
      )}
      {props.camera_id == 0 && (
        <p className={classes.labelChooseCamera}>Choose your camera firstly.</p>
      )}
    </div>
  );
};

export default ImageUploadBox;
