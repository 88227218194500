import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import useApi from 'redux/api/api.hook';

const categoryies = [
  'Deer (Antlered)',
  'Deer (Non Antlered)',
  'Hog',
  'Turkey',
  'People',
  'Vehicle',
  'Predator',
  'Other',
];

const ImageFilterTag = (props) => {
  const [filteredTags, setFilteredTags] = useState<any[]>([]);
  const { tags, tag_assigns } = useApi();

  useEffect(() => {
    updateFilteredTags();
  }, [tags, tag_assigns]);

  const updateFilteredTags = () => {
    const res: any = [];
    const resAssigned: any = [[]];
    const resNone: any = [];

    res.push({ value: 'All', label: 'All Tags' });

    tags.forEach((element) => {
      if (element.tag == '') {
        return;
      }

      let flag_assigned = false;
      for (let i = 0; i < tag_assigns.length; i++) {
        const assign = tag_assigns[i];
        for (let j = 0; j < assign.value.length; j++) {
          if (assign.value[j] == element.tag) {
            if (resAssigned[assign.key] == undefined) {
              resAssigned[assign.key] = [];
            }
            resAssigned[assign.key].push({ label: element.tag, className: assign.key, value: element.tag });
            flag_assigned = true;
            break;
          }
        }
        if (flag_assigned) { break; }
      }
      if (!flag_assigned) {
        resNone.push({ value: element.tag, className: 'None', key: element.tag });
      }
    });

    categoryies.forEach((category) => {
      if (resAssigned[category] && resAssigned[category].length > 0) {
        res.push({ label: category, options: resAssigned[category] });
      }
    });

    if (resNone.length > 0) {
      res.push({ label: 'None', options: resNone });
    }
    setFilteredTags(res);
  };

  const tagChange = (value) => {
    props.onSelectTag(value == 'All' ? '' : value);
  };

  return (
    <Select
      defaultValue="All"
      onSelect={tagChange}
      placeholder="Select A Tag"
      dropdownStyle={{ position: 'fixed' }}
      options={filteredTags}
    />
  );
};

export default ImageFilterTag;
