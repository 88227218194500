import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Bar } from 'react-chartjs-2';
import { Card } from 'antd';

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

interface props {
  data:Array<number>
  color:any
  color_alpha: any
  label: string
  labels:any
}

const ChartBesttime: React.FC<props> = (props) => {
  const dataChart = {
    labels: props.labels,
    datasets: [
      {
        label: props.label,
        data: props.data,
        backgroundColor: props.color_alpha,
        borderColor: props.color,
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    console.log('besttime');
  }, []);

  return (
    <Card bodyStyle={{ padding: '8px' }}>
      <Bar data={dataChart} options={options} />
    </Card>
  );
};

export default ChartBesttime;
