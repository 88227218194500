import React, { useEffect, useState } from 'react';
import * as Api from 'api';
import { Row, Col } from 'antd';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import useAuth from 'redux/auth/auth.hook';

import classes from './style.module.scss';

am4core.useTheme(am4themes_animated);

const DatausageSubscription = () => {
  const [subscriptionPlan, setSubscriptionPlan] = useState<any>();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState<any>();
  const [subscriptionQuantity, setSubscriptionQuantity] = useState<any>();
  const [subscriptionDate, setSubscriptionDate] = useState<any>();
  const { auth: { profile } } = useAuth();

  useEffect(() => {
    if (profile) {
      Api.DATA_USAGE_SUBSCRIPTION().then((res: any) => {
        const subscription = JSON.parse(res.text);
        const plan = subscription.subscribe_type;
        const period = subscription.subscribe_period;
        const quantity = subscription.subscribe_quantity;
        let date = '-';
        if (subscription.subscribed_at) {
          date = subscription.subscribed_at;
        }
        setSubscriptionPlan(plan);
        setSubscriptionPeriod(period);
        setSubscriptionQuantity(quantity);
        setSubscriptionDate(date);
      });
    }
  }, [profile]);

  return (
    <Row>
      <Col md={24} style={{ padding: 8 }}>
        <div style={{ margin: '10px 20px 10px 20px' }}>
          <p>Subscription Plan: {subscriptionPlan}</p>
          <p>Subscription Period: {subscriptionPeriod}</p>
          <p>Subscription Quantity: {subscriptionQuantity}</p>
          <p>Subscription Date: {subscriptionDate}</p>
        </div>
      </Col>
    </Row>
  );
};

export default DatausageSubscription;
