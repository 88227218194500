import { ProfileType } from 'helpers/types';
import { createReducer } from 'redux-act';
import * as actions from './auth.action';

const initialState = {
  profile: undefined,
  message: '',
};

const reducer:any = {
  [actions.setProfile]: (state: any, data: { profile: ProfileType }) => {
    const { profile } = data;
    return { ...state, profile };
  },
  [actions.setMessage]: (state: any, data: { message: String }) => {
    const { message } = data;
    return { ...state, message };
  },
};

export default createReducer(reducer, initialState);
