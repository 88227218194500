/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Card } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import useApi from 'redux/api/api.hook';
import classes from './style.module.scss';

interface props {
  type: number
  counts: number[],
}

const labels = [
  ['Wiseeye Smart Cams', 'SD Card Camera'],
  ['images', 'videos'],
  ['images', 'videos'],
  ['images', 'videos'],
];

const imgs = [
  '/icons/icon-camera-64.png',
  '/icons/deer_icon.png',
  '/icons/hog_icon.png',
  '/icons/icon-turkey-64.png',
];

const colors = [
  '#00c0ef',
  '#00a65a',
  '#e3c567',
  '#877348',
];

const footerLabels = [
  'View Cameras',
  'View Images',
  'View Images',
  'View Images',
];

const footerLinks = [
  '/camera',
  '/image/deer',
  '/image/hog',
  '/image/turkey',
];

const DashboardHeaderCard: React.FC<props> = (props) => {
  const history = useHistory();
  const { filterOptions, setFilterOption } = useApi();

  const clickCard = () => {
    if (props.type == 1) {
      setFilterOption({ ...filterOptions, antlered: '0' });
    }
    history.push(footerLinks[props.type]);
  };

  return (
    <div style={{ padding: 8 }}>
      {/* <Link to={footerLinks[props.type]}> */}
      <Card
        hoverable
        className={classes.card}
        bodyStyle={{ padding: '0' }}
        style={{ background: colors[props.type] }}
        onClick={clickCard}
      >
        <div
          className={classes.boxSmall}
        >
          <div className="inner">
            <h3>{props.counts[0]}</h3>
            <p>{props.counts[1]}<span>&nbsp;{labels[props.type][0]}</span></p>
            <p>{props.counts[2]}<span>&nbsp;{labels[props.type][1]}</span></p>
          </div>
          <img src={imgs[props.type]} alt="" />
        </div>
        <Link to={footerLinks[props.type]}>{footerLabels[props.type]} <i className="fa fa-arrow-circle-right" /></Link>
      </Card>
      {/* </Link> */}
    </div>
  );
};

export default DashboardHeaderCard;
