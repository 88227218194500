import { Col, DatePicker, Form, Row, Select } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useEffect, useState } from 'react';
import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const SubActivityFilter = (props) => {
  const [form] = Form.useForm();
  const { auth: { profile } } = useAuth();
  const { md } = useBreakpoint();
  const [users, setUsers] = useState<any[]>([]);
  const [cameras, setCameras] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);

  const [filterOptions, setFilterOption] = useState({
    camera_id: 0,
    category: 'Deer',
    tag: '',
    startDate: '',
    endDate: '',
  });

  const categoryArr = [
    { value: 'Deer', name: 'Deer' },
    { value: 'Hog', name: 'Hog' },
    { value: 'Turkey', name: 'Turkey' },
  ];

  useEffect(() => {
    if (profile) {
      http_load_tags();
    }
  }, [profile]);

  const http_load_tags = () => {
    Api.SUB_USERS_CAMERAS_TAGS_ALL().then(((res: any) => {
      const jsonObject = JSON.parse(res.text);
      setTags(jsonObject.tags);
      setUsers(jsonObject.users);
      setCameras(jsonObject.cameras);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onValuesChange = (values, allvalues) => {
    const newFilter = { ...filterOptions, ...allvalues };
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const onChangeDate = (date: any, dateString: string[]) => {
    const newFilter = { ...filterOptions, startDate: dateString[0], endDate: dateString[1] };
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  return (
    <div className={classes.wrapper}>
      <Form form={form} style={{ marginTop: 20 }} onValuesChange={onValuesChange}>
        <Row>
          <Col md={8} offset={md ? 3 : 0} xs={24}>
            <div className={classes.formInputLabel}>Choose Camera:</div>
            <Form.Item name="camera_id" className={classes.formInputWrapper} initialValue="0">
              <Select size="large">
                <Option key={0} value="0">All Cameras</Option>
                <OptGroup label="Cameras">
                  {cameras.map((val: any) => (
                    <Option key={val.id} value={val.id}>{val.name}</Option>
                  ))}
                </OptGroup>
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} offset={md ? 2 : 0} xs={24}>
            <div className={classes.formInputLabel}>Choose Tag:</div>
            <Form.Item name="tag" className={classes.formInputWrapper} initialValue="">
              <Select size="large">
                <Option key={0} value="">All</Option> :
                {tags.map((val: any) => (
                  val.tag != '' && (<Option key={val.tag} value={val.tag}>{val.tag}</Option>)
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} offset={md ? 3 : 0} xs={24}>
            <div className={classes.formInputLabel}>Choose Category:</div>
            <Form.Item name="category" className={classes.formInputWrapper} initialValue="Deer">
              <Select size="large">
                {categoryArr.map((val: any) => (
                  <Option key={val.value} value={val.value}>{val.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} offset={md ? 2 : 0} xs={24}>
            <div className={classes.formInputLabel}>Date range:</div>
            <Form.Item className={classes.formInputWrapper}>
              <div>
                <RangePicker size="large" style={{ width: '100%' }} onChange={onChangeDate} />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SubActivityFilter;
