import { DeleteOutlined, EditOutlined, GroupOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Card, Input, Select } from 'antd';
import * as Api from 'api';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const CameraGroupItem = (props) => {
  const { Option } = Select;
  const { auth: { profile } } = useAuth();
  const [editing, setEditing] = useState(props.groupData.id == 0);
  const [groupData, setGroupData] = useState<any>(props.groupData);
  const [children, setChildren] = useState<any[]>([]);
  const [newGroupName, setNewGroupName] = useState(props.groupData.name);
  const [newGroupCameras, setNewGroupCameras] = useState<any[]>(props.groupData.cameras);

  useEffect(() => {
    setGroupData(props.groupData);
    const options: any[] = [];
    for (let i = 0; i < props.cameras.length; i++) {
      const camera = props.cameras[i];
      options.push(<Option key={camera.id} value={camera.id}>{camera.name}</Option>);
    }
    setChildren(options);
  }, [props.cameras, props.groupData]);

  const handleChangeGroupCameras = (value) => {
    setNewGroupCameras(value);
  };

  const handleChangeGroupName = (e) => {
    setNewGroupName(e.target.value);
  };

  const clickEdit = () => {
    setEditing(true);
  };

  const groupUpdated = () => {
    props.onGropUpdate();
  };

  const clickSave = () => {
    const cameraGroup = {
      user_id: profile.user_id,
      group_id: groupData.id,
      group_name: newGroupName,
      camera_ids: newGroupCameras,
    };
    if (groupData.id == 0) {
      Api.CAMERA_GROUP_CREATE(cameraGroup).then(((res: any) => {
        setEditing(false);
        groupUpdated();
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    } else {
      Api.CAMERA_GROUP_UPDATE(cameraGroup).then(((res: any) => {
        setEditing(false);
        groupUpdated();
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  };

  const clickShowMap = () => {
    props.onFocusGroup(newGroupName, newGroupCameras);
  };

  const clickDelete = () => {
    console.log('delete group data', groupData);
    if (window.confirm('Are you sure you want to delete this group?')) {
      if (groupData.id > 0) {
        Api.CAMERA_GROUP_DELETE({ user_id: profile.user_id, group_id: groupData.id }).then((res) => {
          groupUpdated();
        }).catch((error: any) => {
          console.log('error ===>', error);
        });
      } else {
        groupUpdated();
      }
    }
  };

  return (
    <div style={{ marginBottom: '8px' }}>
      <Card
        bodyStyle={{ padding: 8 }}
        hoverable
      >
        <div className={classes.divBody}>
          {editing ?
            <Input className={classes.iconWrap} placeholder="Name" value={newGroupName} onChange={handleChangeGroupName} prefix={<GroupOutlined className={classes.groupItemIcon} />} /> :
            <div className={classes.iconWrap}>  <GroupOutlined className={classes.groupItemIcon} /><b>{newGroupName}</b></div>}
          <Select
            mode="multiple"
            disabled={!editing}
            style={{ width: '100%', margin: '0 8px' }}
            placeholder="Please select"
            value={newGroupCameras}
            onChange={handleChangeGroupCameras}
          // onSelect={(val) => handleChangeGroupCameras(val, true)}
          // onDeselect={(val) => handleChangeGroupCameras(val, false)}
          >
            {children}
          </Select>
          <div className={classes.iconWrap}>
            <EditOutlined className={classes.groupItemIcon} onClick={clickEdit} style={{ display: editing ? 'none' : '' }} />
            <SaveOutlined className={classes.groupItemIcon} onClick={clickSave} style={{ display: editing ? '' : 'none' }} />
            <InfoCircleOutlined className={classes.groupItemIcon} onClick={clickShowMap} />
            <DeleteOutlined className={classes.groupItemIcon} onClick={clickDelete} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CameraGroupItem;
